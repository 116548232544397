<template>
    <div class="tasks">
      <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left-circle" />
        <div class="title">
          <span class="text">{{ $t('home.navTitles.faxin') }}</span>
        </div>
      </div>
      <div class="content" v-if="dailyTasks.length>0">
        <div class="box" v-for="(item,index) in dailyTasks" :key="index" @click="checkTask(item)">
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc.slice(0,20)+'...' }}</div>
          <a-tag v-if="item.complete" class="status" color="green">已完成</a-tag>
          <a-tag v-else class="status">未完成</a-tag>
        </div>
      </div>
      <Task class="task" v-if="showTask" :taskInfo="curTask" @close="closeTaskModal"></Task>
    </div>
</template>
<script>
import Task from '@/components/Task.vue';
export default {
  name: 'Signin',
  data(){
    return {
      showTask: false,
      weekSignCount: 0,
      dailyTasks: [],
      curTask: {}
    }
  },
  components: {
      Task
  },
  mounted(){
    if(this.$store.state.token){
      this.getUserDailyTask();
    }else{
      this.getData();
    }
  },
  methods:{
    checkTask(item){
      if(!this.$store.state.token){
        this.$router.push({name:'login'})
        return;
      }
      this.curTask = item;
      this.showTask = true;
    },
    closeTaskModal(){
      this.showTask = false;
      this.curTask = {};
      this.getUserDailyTask();
    },
    getData(){
      let _this = this
      this.$http.call(this.$http.api.dailyTask).then(function(res){
          console.log(res.data)
          if(res.data.code == '200'){
            _this.dailyTasks = res.data.data;
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    },
    getUserDailyTask(){
      let _this = this
      this.$http.call(this.$http.api.userDailyTask).then(function(res){
          console.log(res.data)
          if(res.data.code == '200'){
            _this.dailyTasks = res.data.data;
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    },
    signin(){
      let _this = this
      this.$http.call(this.$http.api.signin).then(function(res){
          console.log(res.data)
          if(res.data.code == '200'){
            _this.weekSignCount = res.data.data.times;
            _this.$message.success(res.data.msg);
          }else{
            _this.$message.error(res.data.data);
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    }
  }
}
</script>
<style lang="less">
.tasks{
  padding: 30px 50px;
  color: #fff;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .back {
        position: absolute;
        left: 0;
        font-size: 20PX;
        // color: #a8a8a8;
    }
    .title {
        font-weight: bold;
        font-size: 16PX;
        // color: @primary-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title-img {
            height: 50PX;
        }
    }
  }
  .content {
    .box {
      padding: 15PX;
      margin: 15PX auto;
      color: #333;
      position: relative;
      text-align: left;
      .name {
        font-weight: bold;
      }
      .desc {
        opacity: .7;
      }
      .status {
        position: absolute;
        top: 7PX; right: 7PX;
      }
    }
  }
  .task {
    position: absolute;
    top: 0; left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    background: #fff;
    color: #333;
  }
}

</style>
