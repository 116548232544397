<template>
    <div class="video-player">
      <video controls disablePictureInPicture controlsList="nodownload noplaybackrate" ref="videoElement" :src="videoSrc"
      @loadedmetadata="onMetadataLoaded"
      @play="onPlay" @pause="onPause" @ended="onEnded"
       type="audio/mp4"></video>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoPlayer',
    data() {
      return {
        videoDuration: 0,
      };
    },
    props: {
      videoSrc: {
        type: String,
        required: true,
      },
    },
    methods: {
      onPlay() {
        console.log('play')
        this.$emit('play')
        // if(this.videoDuration == 0){
        //   console.log('videoDuration is 0');
        //   return;
        // }
        // this.$emit('play', Math.ceil(this.videoDuration))
      },
      onPause() {
        console.log('pause')
        this.$emit('pause')
      },
      onEnded(){
        console.log('ended')
        this.$emit('ended')
      },
      onMetadataLoaded() {
        this.videoDuration = this.$refs.videoElement.duration;
        console.log(`视频时长为：${this.videoDuration} 秒`);
        this.$emit('metadataLoaded', Math.ceil(this.$refs.videoElement.duration))
      }
    },
  };
</script>
<style lang="less" scoped>
  .video-player {
    height: 100%; width: 100%;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
</style>