<template>
    <div class="audio-player">
      <audio controls controlsList="nodownload noplaybackrate" ref="audioElement" :src="audioSrc"
      @loadedmetadata="onMetadataLoaded"
      @play="onPlay" @pause="onPause" @ended="onEnded"
       type="audio/mp3"></audio>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AudioPlayer',
    data() {
      return {
        audioDuration: 0,
      };
    },
    props: {
      audioSrc: {
        type: String,
        required: true,
      },
    },
    methods: {
      onPlay() {
        console.log('play')
        this.$emit('play')
        // if(this.audioDuration == 0){
        //   console.log('audioDuration is 0');
        //   return;
        // }
        // this.$emit('play', Math.ceil(this.audioDuration))
      },
      onPause() {
        console.log('pause')
        this.$emit('pause')
      },
      onEnded(){
        console.log('ended')
        this.$emit('ended')
      },
      onMetadataLoaded() {
        this.audioDuration = this.$refs.audioElement.duration;
        console.log(`音频时长为：${this.audioDuration} 秒`);
        this.$emit('metadataLoaded', Math.ceil(this.$refs.audioElement.duration))
      }
    },
  };
</script>