<template>
    <div class="task">
        <div v-if="taskInfo.code=='daily_bless'" class="daily-wrapper daily-bless">
            <div class="close">
                <div class="timer" v-if="timeleft>=0"> {{ timeleft }} </div>
                <a-icon v-else type="close-circle" @click="handleClose"></a-icon>
            </div>
            <div class="title"> {{ taskInfo.name }} </div>
            <div class="bg-img">
                <img src="@/assets/imgs/tasks/fenxiang.jpg" alt="">
            </div>
            <div class="desc">
                <div v-if="!blessing && timeleft>0" class="btn-primary bless-btn" @click="handleBless">祈福</div>
                <div>{{ taskInfo.desc }}</div>
            </div>
        </div>
        <div v-if="taskInfo.code=='daily_lesson'" class="daily-wrapper daily-lesson">
            <div class="close">
                <div class="timer" v-if="timeleft>=0"> {{ timeleft }} </div>
                <a-icon v-else type="close-circle" @click="handleClose"></a-icon>
            </div>
            <div class="title"> {{ taskInfo.name }} </div>
            <!-- <div class="bg-img">
                <img src="@/assets/imgs/tasks/fenxiang.jpg" alt="">
            </div> -->
            <div class="desc">
                <div class="audio">
                    <AudioPlayer :audioSrc="taskInfo.assets_url" @play="onPlayAudio" @pause="onPauseAudio" @metadataLoaded="onMetadataLoadedAudio" @ended="onEndedAudio"></AudioPlayer>
                </div>
                <div class="text">
                    {{ taskInfo.desc }} 
                </div>
            </div>
        </div>
        <div v-if="taskInfo.code=='daily_video'" class="daily-wrapper daily-video">
            <div class="close">
                <div class="timer" v-if="timeleft>=0"> {{ timeleft }} </div>
                <a-icon v-else type="close-circle" @click="handleClose"></a-icon>
            </div>
            <div class="title"> {{ taskInfo.name }} </div>
            <div class="bg-video">
                <!-- <video controls controlsList="nodownload noplaybackrate"  :src="taskInfo.assets_url" ></video> -->
                <VideoPlayer :videoSrc="taskInfo.assets_url" @play="onPlayAudio" @pause="onPauseAudio" @metadataLoaded="onMetadataLoadedAudio" @ended="onEndedAudio"></VideoPlayer>
            </div>
            <div class="desc">
                <div class="text">
                    {{ taskInfo.desc }} 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AudioPlayer from './AudioPlayer.vue';
import VideoPlayer from './VideoPlayer.vue';
export default {
  name: "Task",
  data() {
      return {
          timeleft: 0,
          blessing: false,
          intervalId: null
      };
  },
  components: {
      AudioPlayer, VideoPlayer
  },
  props: {
    taskInfo:{
      type: Object
    }
  },
  mounted() {
    // if(this.taskInfo.code == 'daily_bless'){
        this.timeleft = this.taskInfo.time;
    // }
  },
  beforeDestroy() {
    this.stopCountdown();
  },
  methods: {
    startCountdown() {
        let _this = this;
      this.intervalId = setInterval(() => {
        _this.timeleft -= 1;
        if (_this.timeleft < 0) {
          _this.stopCountdown();
          // 倒计时结束完成任务
          _this.doTask({
              'task_id': _this.taskInfo.id,
              'action': 'finish'
          });
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.intervalId);
      //this.intervalId = null;
      if(this.taskInfo.code == 'daily_bless'){
        this.blessing = false;
      }
    },
    handleClose(){
        this.$emit('close');
    },
    doTask(payload){
        let _this = this;
      this.$http.call(this.$http.api.doTask,{data:payload}).then(function(res){
          console.log(res.data)
          if(res.data.data.code && res.data.data.code == '100'){
            _this.$message.success(res.data.data.msg)
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    },
    handleBless(){
        this.startCountdown();
        this.blessing = true;
        this.doTask({
            'task_id': this.taskInfo.id,
            'action': 'start'
        });
    },
    onPlayAudio(){
        console.log('onplayaudio');
        if(this.timeleft > 0){
            this.doTask({
                'task_id': this.taskInfo.id,
                'action': 'start'
            });
            this.startCountdown();
        }
    },
    onPauseAudio(){
        console.log('onpauseaudio');
        this.stopCountdown();
    },
    onEndedAudio(){
        console.log('onendedaudio');
        if(this.timeleft > 0){
            this.startCountdown();
        }
    },
    onMetadataLoadedAudio(ev){
        console.log('onmetadatloaded:'+ev)
        // this.timeleft = ev;
    }
  },
};
</script>
<style lang="less" scoped>
.task {
    display: flex;
    .daily-wrapper{
        flex: 1;
        color: #fff;
        background: #000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .close {
            position: absolute;
            top: 15px; right: 15px;
            opacity: .7;
        }
        .title {
            padding: 15px;
        }
        .bg-img {
            margin: 0 auto;
            width: 80%;
            position: relative;
            img {
                width: 100%;
            }
        }
        .bg-video {
            max-height: 80%;
            max-width: 100%;
        }
        .desc {
            opacity: .7;
            padding: 15px;
            .bless-btn {
                display: inline-block;
                margin-bottom: 7px;
            }
        }
    }.daily-lesson{
        background: url("~@/assets/imgs/tasks/audio_bg.jpg");
        background-size: cover;
        background-position: top center;
        color: #333;
    }
    
}
</style>
